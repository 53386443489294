import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { findScrollableParent } from '@/utils/findScrollElement';
import classNames from 'classnames';
import Flexbox from '@/components/common/Flexbox';
import ScrollableMaskContainer, { ScrollableMaskContainerRef } from '@/components/common/ScrollableMaskContainer';
import { useMediaQuery } from '@chakra-ui/react';
import { useUpdateEffect } from 'react-use';

const PageTabContainer: React.FC<{
  className?: string;
  justify?: Partial<Record<'sm' | 'base', 'start' | 'center' | 'end'>>;
}> = ({ children, className, justify }) => {
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [scrollableNode, setScrollableNode] = useState<HTMLElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [sections, setSections] = useState<Array<HTMLElement>>([]);
  const [topOffset, setTopOffset] = useState<number>(0);

  const [aboveTablet] = useMediaQuery('(min-width: 768px)');

  const tabScrollerRef = useRef<ScrollableMaskContainerRef | null>(null);

  useEffect(() => {
    if (!node) return;
    const find = () => {
      const scrollableNode = findScrollableParent(node);
      setScrollableNode(scrollableNode);
    };
    find();
    window.addEventListener('resize', find);
    return () => {
      window.removeEventListener('resize', find);
    };
  }, [node, children]);

  useUpdateEffect(() => {
    const tabNodes = tabScrollerRef.current?.scroller?.childNodes;
    if (!tabNodes) return;
    // Find tabNode that has a data-active attribute, then scrollIntoView
    for (let i = 0; i < tabNodes.length; i++) {
      const tabNode = tabNodes[i] as HTMLElement;
      if (tabNode.getAttribute('data-active') === 'true') {
        tabNode?.scrollIntoView({ inline: 'center' });
        break;
      }
    }
  }, [scrollPosition, children]);

  useEffect(() => {
    if (!scrollableNode) return;
    // onScroll -> setScrollPosition
    const onScroll = () => {
      setScrollPosition(scrollableNode.scrollTop);
    };
    scrollableNode.addEventListener('scroll', onScroll);
    return () => {
      scrollableNode.removeEventListener('scroll', onScroll);
    };
  }, [scrollableNode]);

  useEffect(() => {
    if (!node) return;
    const findSections = () => {
      const sections = Array.from(node.querySelectorAll('section'));
      console.log(sections);
      // filter out sections that are having display: none
      setSections(
        sections.filter((section) => {
          const style = window.getComputedStyle(section);
          return style.display !== 'none';
        })
      );
    };
    findSections();
    window.addEventListener('resize', findSections);

    return () => {
      window.removeEventListener('resize', findSections);
    };
  }, [node, children]);

  const tabs = useMemo(
    () =>
      sections.map((section) => {
        const id = section.getAttribute('id');
        const title = section.getAttribute('data-title');
        return { id, title, section };
      }),
    [sections]
  );

  useEffect(() => {
    const findTopOffset = () => {
      const firstSection = tabs[0]?.section;
      const pageTab = document.getElementById('page-tab');
      if (!firstSection || !node || !pageTab) return;
      setTopOffset(node.offsetTop - firstSection.offsetTop + pageTab.clientHeight);
    };
    findTopOffset();
    window.addEventListener('resize', findTopOffset);
    return () => {
      window.removeEventListener('resize', findTopOffset);
    };
  }, [tabs, node]);

  const scrollToSectionById = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const sectionId = event.currentTarget.getAttribute('data-sectionid');
      if (!sectionId) return;
      const section = document.getElementById(sectionId);
      if (!section) return;
      // if the browser does not support the scrollIntoView method, fallback to default scroll

      scrollableNode?.scrollTo({
        top: aboveTablet && sectionId === tabs[0]?.id ? section.offsetTop : section.offsetTop - topOffset,
        behavior: 'smooth',
      });
    },
    [scrollableNode, topOffset, tabs, aboveTablet]
  );

  return (
    <div id={'page-tab-container'} ref={setNode} className={classNames(className)}>
      <Flexbox
        id={'page-tab'}
        align={'center'}
        justify={'center'}
        className={classNames(
          'fixed top-0 left-0',
          'py-1 sm:py-2',
          'z-[60] w-full h-14 sm:h-16',
          'bg-white',
          'transition-transform transform duration-300',
          scrollPosition >= tabs[0]?.section?.offsetTop ? 'translate-y-0' : '-translate-y-full'
        )}
      >
        <ScrollableMaskContainer
          direction={'horizontal'}
          ref={tabScrollerRef}
          className={classNames('flex', 'sm:mx-auto sm:max-w-[90rem]', 'shadow-tabBottomSM', 'sm:shadow-tabBottomXL', {
            'sm:justify-center': !justify?.sm || justify?.sm === 'center',
            'sm:justify-end': justify?.sm === 'end',
            'sm:justify-start': justify?.sm === 'start',
            'justify-center': justify?.base === 'center',
            'justify-end': justify?.base === 'end',
            'justify-start': !justify?.base || justify?.base === 'start',
          })}
        >
          {tabs.map((tab) => {
            const { id, title, section } = tab;
            const isActive =
              scrollPosition >= section.offsetTop - topOffset &&
              scrollPosition < section.offsetTop + section.offsetHeight - topOffset;
            return (
              <Flexbox
                as={'button'}
                type={'button'}
                onClick={scrollToSectionById}
                key={id}
                data-sectionid={id}
                align={'center'}
                justify={'center'}
                data-active={isActive}
                className={classNames(
                  'relative',
                  'py-2 px-4',
                  'sm:flex-none h-full flex-shrink-0',
                  'sm:min-w-30 min-w-15',
                  'text-sm',
                  isActive ? 'text-primary' : 'text-gray-400',
                  'cursor-pointer overflow-hidden'
                )}
              >
                <p>{title}</p>
                <div
                  className={classNames(
                    'absolute bottom-0',
                    'h-px sm:h-0.5 w-full',
                    'bg-gray900',
                    'transition-transform transform duration-300',
                    !isActive && '-translate-x-full'
                  )}
                />
              </Flexbox>
            );
          })}
        </ScrollableMaskContainer>
      </Flexbox>
      {children}
    </div>
  );
};

export default PageTabContainer;
